body {
  font-family: Cascadia_Regular;
  padding: 0;
}

.app {
  height: 100vh;
  width: 100vw;
  text-align: left;
  background-size: cover;
  background: url("./assets/background.png") no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.app-logo {
  margin-top: 150px;
  margin-left: 90px;
  margin-right: 90px;
  /* width: 51.8vw; */
  width: 995px;
}

.app-content {
  margin-top: -40px;
  margin-left: 150px;
  font-style: normal;

  font-weight: 350;
  font-size: 1.354vw;

  color: #ffffff;
}

.contact-container {
  position: absolute;
  right: 50px;
  bottom: 50px;
}

.app-contact {
  font-size: 24px;
  padding: 25px;
  color: #fff;

  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 10px;
}

.contact {
  color: #ff9501;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1176px) {
  .app-logo {
    margin-top: 150px;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 90vw;
  }

  .app-content {
    margin-left: 10vw;
    width: 60vw;
    font-size: 2.685vw;
  }

  .contact-container {
    left: 5vw;
    right: 5vw;
    width: 90vw;

    display: flex;
    align-items: center;
  }

  .app-contact {
    margin: auto;
    max-width: 90vw;
  }
}

@media only screen and (max-width: 768px) {
  
.app-content {
  margin-top: -20px;
}
}

@font-face {
  font-family: Cascadia_Regular;
  src: url("./assets/fonts/Cascadia/Cascadia Code PL Regular 400.otf");
}
